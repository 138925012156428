.filter-input {
  display: grid;
  width: max-content;
  margin-right: 10px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  grid-auto-rows: max-content;

  label {
    margin-bottom: 10px;
  }

  input {
    padding: 5px 10px;
    height: 38px;
    border: {
      style: solid;
      color: #ccc;
      width: 1px;
      radius: 4px;
    }

    font-size: 16px;
  }
}
