.filter-select{
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    display: grid;
    grid-auto-rows: max-content;
    margin-right: 10px;

    label{
        margin-bottom: 10px;
    }
}