.nav-wrapper {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        text-align: center;
        transition: 0.4s;

        a {
          color: #a9a7a7;
          text-decoration: none;
          transition: 0.4s;
          padding: 20px 30px;
          display: block;
        }

        .active {
          background-color: #196cad;
          color: #fff;
        }
      }

      li:hover {
        background-color: #196cad;
        transition: 0.4s;
        cursor: pointer;

        a {
          color: #fff;
          transition: 0.4s;
        }
      }
    }
  }
}

.main-wrapper {
  width: 100%;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
}
