body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table p {
  margin-bottom: 0.25rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bi {
  display: inline-block;
  vertical-align: -0.125em;
}
.button-row {
  width: 203px;
}
.edit-row {
  width: 83px;
}

.kg-row {
  width: 100px;
}

.required:after {
  content: " *";
  color: red;
}
.ReactTable .rt-td {
  text-align: center;
}

.form-select__control {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.1rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select__menu {
  z-index: 3 !important;
}

.is-invalid > .form-select__control,
.is-invalid > .form-select__control:hover {
  border-color: red;
}

.valid > .form-select__control,
.valid > .form-select__control:hover {
  border-color: green;
}

.blink {
  animation: blinker 1.5s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

#tooltip-status .tooltip-inner {
  max-width: 280px;
}

.subcell {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.subcell:first-child {
  padding-bottom: 5px;
}
.subcell:nth-child(2) {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 5px;
}

.cellWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
}

.table-header {
  font-size: 14px;
}

.weightSum {
  text-align: right;
}

table p {
  font-size: 14px;
}

.table > :not(caption) > * > * {
  padding: 5px;
}

.modal-title {
  font-size: 16px;
  padding: 0 10px;
}

.delete-modal .modal-footer {
  justify-content: space-evenly;
}

.delete-modal .modal-title {
  display: flex;
  justify-content: center;
  width: 100%;
}
.datePicker {
  width: 100%;
}

.datePicker input {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border-top: none;
  border-left: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: none;
  border-bottom: 1px solid #aeaeae;
  border-radius: 0 0.3rem 0.3rem 0.3rem;
  position: absolute;
  right: -85px;
  top: 0px;
}
.react-datepicker__header {
  text-align: center;
  background-color: #0d6efd;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker__day-name {
  color: #fff;
}
.react-datepicker__header.react-datepicker__header--time {
  padding: 20px 0;
}
ul.react-datepicker__time-list {
  height: 204px;
}
.react-datepicker__time {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__close-icon::after {
  background-color: #dc3545;
}
