.waiting-delivery {
  padding: 30px 0;
}

.deliveries-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border: none;
    border-bottom: 1px solid #6e6e6e5c;

    &:first-child {
      border-bottom: 2px solid #6e6e6e5c;
      font-size: 15px;

      th {
        text-align: left;
        padding: 5px 2px;
      }
    }

    td {
      padding: 12px 2px;
      font-size: 14px;
    }

    &:last-child {
      border-width: 2px;
    }

    .table-scn {
      background: #f7de17;
      padding: 5px 8px;
      border-radius: 6px;
      color: #000;
    }

    .table-status {
      background: #df2222;
      padding: 5px 8px;
      border-radius: 6px;
      color: #fff;
    }
  }
}
