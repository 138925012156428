.top-filters{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;

    .filter-input{
        input{
            grid-row: 2;
        }
    }
}