/* stylelint-disable no-descending-specificity */
.status-filters {
  clear: both;
  display: flex;
  padding: 30px 0;
  position: relative;

  .filter-input {
    flex-basis: unset;
    max-width: max-content;
  }

  .second-status-wrapper {
    margin-left: auto;
    display: flex;

    label {
      padding: 6px 15px;
      border: 2px solid #8989893d;
      cursor: pointer;
      transition: 0.4s;
      color: #000;
      margin-left: -1px;

      &:hover {
        transition: 0.4s;
        color: #196cad;
        border-color: #4791cb;
      }
    }

    .filter-input {
      margin-right: 0;

      &:last-child {
        label {
          border-radius: 0 4px 4px 0;
        }
      }

      &:first-child {
        label {
          border-radius: 4px 0 0 4px;
        }
      }
    }

    input[type='radio'] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    input[type='radio']:checked + label {
      border-color: #4791cb;
      color: #196cad;
    }
  }

  input.status-input {
    position: absolute;
    left: -99999em;
    top: -99999em;

    & + label {
      cursor: pointer;
      float: left;
      margin-right: 30px;
      padding: 12px 20px;
      position: relative;
      background: linear-gradient(#196cad, #196cad) bottom / 0 2.5px no-repeat;
      background-size: 0% 2.5px;
      transition: 0.6s background-size;
      font: {
        size: 14px;
        weight: 600;
      }

      &:hover {
        background-size: 100% 2.5px;
        transition: 0.6s background-size;
        color: #196cad;
      }
    }

    &:checked + label {
      background: linear-gradient(#196cad, #196cad) bottom / 0 2.5px no-repeat;
      background-size: 100% 2.5px;
      transition: 1s;
      z-index: 1;
      color: #196cad;
    }
  }

  .add-button-wrapper {
    .add-new-button {
      font-size: 16px;
      padding: 6px 15px;
      height: 22px;
      margin-left: 15px;
      border-radius: 4px;
      border: none;
      background-color: #196cad;
      color: #fff;
      cursor: pointer;
    }

    .arrow {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-left: 10px;
      position: relative;
      bottom: 2px;
    }

    .down {
      transform: rotate(45deg);
    }

    ul {
      list-style: none;
      margin-top: 10px;
      width: max-content;
      position: absolute;
      right: 34px;
      padding: 0;
      border: 1px solid #ccc;
      border-radius: 4px;

      li {
        background-color: #fff;
        transition: 0.4s;

        a {
          text-decoration: none;
          color: #000;
          display: block;
          padding: 10px 16px;
          transition: 0.2s;
        }
      }

      li:hover {
        background-color: #196cad;
        transition: 0.4s;

        a {
          color: #fff;
          transition: 0.2s;
        }
      }
    }
  }
}
