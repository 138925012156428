html, body, #root, .main-container {
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.main-container {
    margin-left: 300px;
}

h1 {
  margin-bottom: 1rem;
}

.star {
  color: red;
}